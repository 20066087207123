import React, { useState, useRef, useEffect, useContext } from 'react';
import { string, shape, func, bool, arrayOf } from 'prop-types';
import classnames from 'classnames';
import { Button } from '@andes/button';
import { Form } from '@andes/form';
import { TextField } from '@andes/textfield';
import StaticPropsContext from '../../../context/static-props';
import StyledLabel from '../../../styled-label';
import { trackEvent } from '../../../../lib/tracking';
import useIsMounted from '../../../../hooks/use-mounted';

const namespace = 'ui-qadb__make-question-sa';
const MakeQuestionSA = ({
  id,
  title,
  item_id,
  action,
  disabled,
  onCreateQuestion,
  onChangeQuestion,
  url,
  app,
  subtitles,
  initialValue,
  track,
  parameters,
}) => {
  const { deviceType } = useContext(StaticPropsContext);
  const [value, setValue] = useState(initialValue || null);
  const [disabledButton, setDisabledButton] = useState(disabled);
  const [isFocus, setIsFocus] = useState(false);
  const inputRef = useRef(null);
  const isMobile = deviceType === 'mobile';
  const fullWidthButton = !!isMobile;
  const isMounted = useIsMounted();

  useEffect(() => {
    setDisabledButton(disabled);
    setValue(initialValue);
  }, [initialValue, disabled]);

  const onInputChange = e => {
    setValue(e.target.value);
    setIsFocus();
  };

  const onInputFocus = () => {
    if (track) {
      trackEvent(track);
    }
  };

  const handleMakeQuestion = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (value && value.trim() !== '') {
      setDisabledButton(true);
      onChangeQuestion(value.trim());
      onCreateQuestion({
        item_id,
        question: value.trim(),
        failure: action.failure,
        success: action.success,
        app,
        parameters,
        form: id,
      });
    } else {
      inputRef.current.focus();
    }
  };

  return (
    <div className={namespace}>
      {title && (
        <StyledLabel
          as="p"
          color="BLACK"
          font_family="SEMIBOLD"
          font_size="MEDIUM"
          className={`${namespace}__title`}
          text={title}
        />
      )}
      <Form className={`${namespace}__form`} action={url} method="POST" onSubmit={handleMakeQuestion}>
        <input type="hidden" name="itemId" value={item_id} />
        <div className={`${namespace}__content`}>
          <TextField
            name="question"
            data-testid="questions-input"
            className={classnames([`${namespace}__input`, !subtitles.length && 'no-message'])}
            placeholder={action.question_place_holder}
            ref={innerRef => {
              inputRef.current = innerRef;
            }}
            textbox
            helper={subtitles && subtitles.map(s => s.text).join('')}
            autoComplete="off"
            value={value}
            onChange={onInputChange}
            onFocus={onInputFocus}
            maxLength={2000}
            multiline
            focus={isFocus}
          />
        </div>
        <Button
          data-testid="questions-button"
          className={`${namespace}__button`}
          hierarchy="loud"
          type="submit"
          disabled={!isMounted || disabledButton}
          fullWidth={fullWidthButton}
        >
          {action.text}
        </Button>
      </Form>
    </div>
  );
};

MakeQuestionSA.propTypes = {
  id: string.isRequired,
  app: string.isRequired,
  title: string.isRequired,
  initialValue: string,
  subtitles: arrayOf(shape({})),
  item_id: string.isRequired,
  url: string.isRequired,
  action: shape({
    text: string.isRequired,
  }).isRequired,
  disabled: bool,
  onCreateQuestion: func.isRequired,
  onChangeQuestion: func.isRequired,
  track: shape({}),
  parameters: shape({}),
};

MakeQuestionSA.defaultProps = {
  disabled: false,
  track: null,
  subtitles: [],
  parameters: null,
};

export default MakeQuestionSA;
