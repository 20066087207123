import React, { useState, useRef } from 'react';
import { bool, string, func, shape } from 'prop-types';
import classnames from 'classnames';
import { TextField } from '@andes/textfield';
import { Form } from '@andes/form';
import StyledLabel from '../../../styled-label';
import IconFactory from '../../../icons/factory-builder';
import Tooltip from '../../../tooltip';
import { trackEvent } from '../../../../lib/tracking';
import useIsMounted from '../../../../hooks/use-mounted';
import TOOLTIP from '../../../tooltip/constants';

const namespace = 'ui-pdp-qadb__search-bar';

const TOOLTIP_HOW_TO_MAKE_QUESTION = 'TOOLTIP_HOW_TO_MAKE_QUESTION';
const TOOLTIP_CLASSNAME = 'andes-tooltip-data';

const getTooltipContent = body => <div>{body.text}</div>;

const SearchBar = ({
  placeholder,
  item_id,
  product_id,
  focus,
  title,
  track,
  query,
  shouldBlurOnSubmit,
  showClose,
  onSearchQuery,
  onCancelSearchQuery,
  onCloseHowToMakeQuestionTooltip,
  onSearchBlur,
  onSearchFocus,
  tooltip,
  accessibility_text,
}) => {
  const [value, setValue] = useState('');
  const inputRef = useRef(null); // ref help for textfield
  const isMounted = useIsMounted();

  const onInputChange = e => {
    setValue(e.target.value);
    // checks if query is not null, this ocurrs on webmobile when the user changes the value using the
    // predective, which is onBlur
    if (query && query.trim() !== '' && e.target.value.trim() === '') {
      onCancelSearchQuery({ item_id, product_id });
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (value !== '') {
      onSearchQuery({ query: value, item_id, product_id });
      if (shouldBlurOnSubmit) {
        inputRef.current.blur();
      }
    }
  };

  const handleOnBlur = e => {
    if (e.relatedTarget?.className === TOOLTIP_CLASSNAME) {
      // En @andes-v8 el tooltip se roba el foco, por lo que es necesario devolverselo al input.
      // TODO: Cuando se fixee, eliminar la sentencia en cuestion.
      e.relatedTarget.focus = null;
      inputRef.current.focus();
    } else {
      e.relatedTarget?.click();
    }

    e.preventDefault();
    onSearchBlur();
  };

  const handleOnFocus = e => {
    e.preventDefault();
    trackEvent(track);
    onSearchFocus();
  };

  const searchBarContent = (
    <div className={classnames(namespace)}>
      <StyledLabel
        as="h3"
        color="BLACK"
        font_family="SEMIBOLD"
        font_size="MEDIUM"
        className="ui-pdp-search-bar__title"
        text={title}
      />
      <Form
        method="GET"
        action={`/p/${product_id}/qadb?query=${value}&product_id=${product_id}&item_id=${item_id}`}
        onSubmit={handleOnSubmit}
      >
        <input type="hidden" name="item_id" value={item_id} />
        <input type="hidden" name="product_id" value={product_id} />
        <div className={`${namespace}__input-container`}>
          <TextField
            className={classnames(`${namespace}__input`, {
              [`${namespace}__input--shows-close`]: showClose,
            })}
            data-testid="search-bar-input"
            name="query"
            placeholder={placeholder}
            ref={innerRef => {
              inputRef.current = innerRef;
            }}
            autoComplete="off"
            value={value}
            onChange={onInputChange}
            focus={focus}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
          />
          {showClose && (
            <span
              data-testid="search-bar-close"
              role="presentation"
              onClick={e => {
                e.preventDefault();
                onCancelSearchQuery({ item_id, product_id });
              }}
              className={`${namespace}__clear-search`}
            >
              {IconFactory({ id: 'close' })}
            </span>
          )}
          <button
            data-testid="search-bar-submit"
            type="submit"
            onKeyUp={handleOnSubmit}
            onClick={handleOnSubmit}
            className={`${namespace}__search`}
            disabled={!isMounted}
            aria-label={accessibility_text}
          >
            {IconFactory({ id: 'search', color: 'WHITE' })}
          </button>
        </div>
      </Form>
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip
        id={TOOLTIP_HOW_TO_MAKE_QUESTION}
        className={`${namespace}__tooltip`}
        title={tooltip.title && tooltip.title.text}
        content={getTooltipContent(tooltip.body)}
        side={TOOLTIP.SIDE.BOTTOM_LEFT}
        trigger={TOOLTIP.TRIGGER.NO_TRIGGER}
        visible={tooltip.visible}
        closable={!!tooltip?.closable}
        offsetX={1}
        offsetY={25}
        type={TOOLTIP.TYPE.HIGHLIGHT}
        onClose={() => onCloseHowToMakeQuestionTooltip()}
      >
        {searchBarContent}
      </Tooltip>
    );
  }

  return searchBarContent;
};

SearchBar.propTypes = {
  placeholder: string.isRequired,
  title: string.isRequired,
  item_id: string.isRequired,
  focus: bool,
  showClose: bool,
  product_id: string.isRequired,
  track: shape({}).isRequired,
  query: string,
  shouldBlurOnSubmit: bool,
  onSearchBlur: func.isRequired,
  onSearchFocus: func.isRequired,
  onSearchQuery: func.isRequired,
  onCancelSearchQuery: func.isRequired,
  onCloseHowToMakeQuestionTooltip: func.isRequired,
  tooltip: shape({}),
  accessibility_text: string,
};

SearchBar.defaultProps = {
  showClose: false,
  shouldBlurOnSubmit: false,
  focus: false,
  query: '',
  tooltip: null,
};

export default SearchBar;
