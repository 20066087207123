import React, { useState, useContext, useRef } from 'react';
import { func, shape, string, bool } from 'prop-types';
import classnames from 'classnames';
import { Button } from '@andes/button';
import { TextField } from '@andes/textfield';
import { Form } from '@andes/form';
import StaticPropsContext from '../../../context/static-props';

const namespace = 'ui-pdp-qadb__question-form';

const QuestionForm = ({
  id,
  action,
  initialValue,
  className,
  item_id,
  disabled,
  product_id,
  onCreateQuestion,
  onCancelSearchQuery,
}) => {
  const [value, setValue] = useState(initialValue);
  const inputRef = useRef(null);
  const { deviceType } = useContext(StaticPropsContext);
  const isDesktop = deviceType === 'desktop';

  const onInputChange = e => {
    setValue(e.target.value);
  };

  const handleOnSubmit = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (value.trim() !== '') {
      onCreateQuestion({ item_id, question: value, failure: action.failure, success: action.success, form: id });
      setValue('');
    } else {
      inputRef.current.focus();
    }
  };

  const handleOnCancel = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onCancelSearchQuery(e, { item_id, product_id });
  };

  return (
    <div className={classnames(namespace, className)}>
      <Form className={`${namespace}__form`} method="POST" onSubmit={handleOnSubmit}>
        <input type="hidden" name="itemId" value={action.item_id} />
        <div className={`${namespace}__form__content`}>
          <TextField
            name="question"
            data-testid="question-form-input"
            className={`${namespace}__form__input`}
            placeholder={action.form.placeholder}
            ref={innerRef => {
              inputRef.current = innerRef;
            }}
            autoComplete="off"
            autoFocus
            value={value}
            onChange={onInputChange}
            multiline
          />
        </div>
        <div className={classnames(`${namespace}__form__actions`, 'mt-24', 'mb-32')}>
          <Button
            data-testid="question-form-submit"
            className={`${namespace}__form__actions__button`}
            hierarchy="loud"
            disabled={disabled}
            type="submit"
          >
            {action.form.confirm}
          </Button>
          {isDesktop && action.form.cancel && (
            <Button
              data-testid="question-form-cancel"
              className={`${namespace}__form__actions__button`}
              hierarchy="quiet"
              type="cancel"
              onClick={handleOnCancel}
            >
              {action.form.cancel}
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

QuestionForm.propTypes = {
  id: string.isRequired,
  className: string,
  action: shape({
    form: shape({
      title: string.isRequired,
      confirm: string.isRequired,
      placeholder: string.isRequired,
      cancel: string,
    }).isRequired,
  }).isRequired,
  initialValue: string,
  item_id: string.isRequired,
  onCreateQuestion: func.isRequired,
  onCancelSearchQuery: func,
  product_id: string,
  disabled: bool,
};

QuestionForm.defaultProps = {
  className: null,
  initialValue: '',
  onCancelSearchQuery: null,
  product_id: null,
  disabled: false,
};

export default QuestionForm;
